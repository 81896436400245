<template>
  <main v-if="!isViewPayslip" class="p-4 min-h-screen">
    <h4
      class="no-printable md:mt-3 md:text-sm md:text-left text-xs text-headerText"
    >
      Payroll / Payroll History
    </h4>

    <div class="no-printable w-full flex justify-start mt-4">
      <easiButton
        @click="goBack"
        variant="text"
        color="dark-800"
        size="small"
        class="-ml-2"
      >
        <i class="text-base fas fa-angle-left mr-3 cursor-pointer"></i>
        <span class="text-base">Back</span>
      </easiButton>
      <div
        class="no-printable w-full flex items-center justify-end gap-2 md:gap-4"
      >
        <div class="flex md:hidden gap-x-3">
          <easiButton
            v-for="action in actions"
            :key="action.title"
            variant="outlined"
            rounded="lg"
            size="small"
            border="2"
            :loading="action.loading"
            @click="action.action"
          >
            <span class="py-1 px-2 flex items-center justify-center">
              <i :class="[action.icon, '']" />
            </span>
          </easiButton>
        </div>
      </div>
    </div>

    <div class="w-full md:w-11/12 mx-auto">
      <h4
        class="hidden md:block text-left text-bold text-dark-900 font-medium sm:text-xl md:text-2xl"
      >
        Total Run Payroll For {{ months[payload.month] }} {{ payload.year }}
      </h4>
      <div class="">
        <PayrollCard :on-print="onPrint" :data="payrollCardData" />
      </div>

      <div class="my-6 md:mb-10 w-full mx-auto">
        <div
          class="flex md:flex-row flex-col md:justify-between justify-center mx-auto w-full md:gap-y-0 gap-y-8 md:items-center"
        >
          <div
            class="no-printable mt-4 w-full mx-auto flex flex-wrap md:flex-nowrap"
          >
            <div class="col-span-2 md:col-span-1 w-full flex items-center">
              <div class="w-full">
                <easiSearch
                  placeholder="Search employee"
                  useNewclass
                  v-model="searchText"
                  @search="searchFN"
                />
              </div>

              <PayrollFilter
                ref="payrollHistoryDetailRef"
                @update="filterFN"
                class="ml-4"
              />
            </div>

            <div
              class="col-span-2 md:col-span-1 flex items-center justify-center md:justify-end"
            >
              <easiButton
                @click="toRunPayroll"
                class="py-4 hidden md:block mx-2 w-44 md:w-auto"
                color="primary"
              >
                <span class=""> Run Payroll </span>
              </easiButton>

              <easiButton
                @click="sendPayslip"
                :loading="loading"
                class="py-4 w-44 md:w-auto mt-5 md:mt-0"
              >
                <span class=""> Send Payslip(s) </span>
              </easiButton>
            </div>
          </div>
        </div>
      </div>
      <easiActiveFilter @close="removeFilter" :filters="activeFilters" />
      <div
        class="no-printable w-full flex items-center justify-end gap-2 md:gap-4 mt-8"
      >
        <div
          class="bg-white border-2 border-outlineGray p-2 rounded-2xl hidden md:flex space-x-3"
        >
          <easiButton
            variant="outlined"
            size="small"
            rounded="lg"
            border="2"
            v-for="action in actions"
            :key="action.title"
            :loading="action.loading"
            @click="action.action"
          >
            <span class="flex items-center gap-2">
              {{ action.title }}

              <i :class="action.icon" />
            </span>
          </easiButton>
        </div>
      </div>

      <div class="w-full mt-2 mb-4"></div>
      <div
        v-if="
          payrollForMonth &&
          payrollForMonth.salaryRecords &&
          payrollForMonth.salaryRecords.length
        "
      >
        <payroll-details-table
          @selected="updateSelectedEmployees"
          :is-search="isSearch"
          :searchText="searchText"
          :paymentStatus="paymentStatusFilterOption"
        />
      </div>

      <div
        v-if="
          payrollForMonth &&
          payrollForMonth.salaryRecords &&
          !payrollForMonth.salaryRecords.length &&
          payrollForMonth.bonusOrDeductionRecords &&
          payrollForMonth.bonusOrDeductionRecords.length
        "
      >
        <PayrollBonusHistoryTable
          @selected="updateSelectedEmployees"
          :is-search="isSearch"
          :searchText="searchText"
          :paymentStatus="paymentStatusFilterOption"
        />
      </div>
    </div>

    <easiSuccess v-if="shareSuccess" @close="shareSuccess = false">
      <template v-slot:message>
        <span>Payroll information shared successfully</span>
      </template>
    </easiSuccess>
    <easiSuccess v-if="payslipSuccess" @close="payslipSuccess = false">
      <template v-slot:message>
        <span>Payslip(s) sent successfully</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="queryLoading" />
  </main>
  <main v-else>
    <view-payslip />
  </main>

  <ShareToAdmin
    :input="{
      month: payload.month,
      year: payload.year,
      timeline: payload.timeline,
      biweekly: payload.biweekly,
    }"
    :isOpen="shareModal"
    type="detailed"
    @close="shareModal = false"
  />
</template>

<script setup>
import PayrollDetailsTable from "@/components/Payroll/History/PayrollDetailsTable.vue";
import PayrollBonusHistoryTable from "@/components/Payroll/History/PayrollBonusHistoryTable.vue";
import ViewPayslip from "@/components/Payroll/Payslip/ViewPayslip.vue";
import PayrollCard from "@/components/Payroll/History/PayrollCard.vue";
import PayrollFilter from "@/components/Filters/PayrollFilter.vue";
import PayrollSearch from "@/components/Search/PayrollSearch.vue";
import ShareToAdmin from "./ShareToAdmin.vue";

import { computed, ref, onMounted, watch, reactive } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { storeToRefs } from "pinia";

const store = useDataStore();
const { query, mutate } = store;
const { downloadBase64, printPDF } = helperFunctions;

const route = useRoute();
const router = useRouter();
const toast = useToast();

const isViewPayslip = computed(() => {
  const queryParams = route.query;
  return queryParams && queryParams.id;
});

console.log("isViewPayslip", isViewPayslip.value);

const onPrint = ref(false);
const shareLoading = ref(false);
const shareModal = ref(false);
const shareSuccess = ref(false);
const payslipSuccess = ref(false);
const paymentStatusFilterOption = ref("all");

const printLoading = ref(false);
const downloadLoading = ref(false);
const queryLoading = ref(false);
const loading = ref(false);
const searchText = ref("");
let isSearch = ref(false);

const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});
const activeFilters = ref([]);
const payrollHistoryDetailRef = ref(null);

const payrollForMonth = computed(() => store.getPayrollHistoryForMonth);

console.log("Payroll For The Month.", payrollForMonth.value);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const shareOptions = ref([]);

const payload = ref({
  month: null,
  year: null,
  timeline: null,
  biweekly: null,
  sort: {},
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
  },
  filter: [],
});

const actions = computed(() => {
  return [
    {
      title: "Print",
      icon: "pi pi-print",
      loading: printLoading.value,
      action() {
        handlePrint(true);
      },
    },
    {
      title: "Share",
      icon: "pi pi-share-alt",
      action() {
        handleShare();
      },
    },
    {
      title: "Download",
      icon: "pi pi-download",
      loading: downloadLoading.value,
      action() {
        handleDownload(false);
      },
    },
  ];
});

let selectedEmployees = ref([]);
const currentPage = ref(0);
const pageSize = ref(2);
let visibleData = ref([]);

const payrollCardData = computed(() => {
  if (payrollForMonth.value) {
    const arr = [
      ...payrollForMonth.value.totalPaidEmployeesSalary,
      ...payrollForMonth.value.totalPaidEmployeesBonus,
      ...payrollForMonth.value.totalPaidEmployeesStatutory,
      ...payrollForMonth.value.totalDeductedEmployees,
    ];

    const employeesTotal = [...new Set(arr)].length;

    // console.log(arr, employeesTotal);
    return {
      employeesTotal,
      grossTotal: payrollForMonth.value && payrollForMonth.value.totalGross,
      netTotal: payrollForMonth.value && payrollForMonth.value.totalNet,
      deductionTotal:
        payrollForMonth.value && payrollForMonth.value.totalDeductions,
    };
  } else {
    return {
      employeesTotal: 0,
      grossTotal: 0,
      netTotal: 0,
      deductionTotal: 0,
    };
  }
});

watch(searchText, async (val) => {
  if (val.trim() === "") {
    payload.value.paging.search = "";
    queryViewPayrollForMonthV3();
    isSearch.value = false;
  }
});

onMounted(async () => {
  const payrollDate = route.params.date.split(",");

  // console.log("PAYROLL DATE", payrollDate);
  payload.value.month = parseInt(payrollDate[0]);
  payload.value.year = parseInt(payrollDate[1]);
  payload.value.timeline = payrollDate[2];
  payload.value.biweekly = payrollDate[3];

  queryViewPayrollForMonthV3();
});

const sendPayslip = async () => {
  // console.log("PAYLOAD", payload.value);
  const obj = {
    employeeIds: selectedEmployees.value,
    month: payload.value && payload.value.month,
    year: payload.value && payload.value.year,
    timeline:
      payload.value && payload.value.timeline
        ? payload.value.timeline
        : "MONTHLY",
    biweekly: payload.value && payload.value.biweekly,
  };

  if (selectedEmployees.value.length) {
    try {
      loading.value = true;

      const res = await mutate({
        endpoint: "SendPaySlipEmails",
        data: {
          input: obj,
        },
        service: "PAYROLL",
      });

      loading.value = false;
      if (res.success) {
        payslipSuccess.value = true;
        shareSuccess.value = true;
        queryViewPayrollForMonthV3();
        setTimeout(() => {
          payslipSuccess.value = false;
          shareSuccess.value = false;
        }, 4000);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      loading.value = false;
      console.log(err);
    }
  } else {
    toast.error("Please select an employee");
  }
};

const queryViewPayrollForMonthV3 = async () => {
  let data = {
    month: payload.value.month,
    year: payload.value.year,
    timeline: payload.value.timeline,
    ...payload.value,
  };
  if (payload.value.timeline === "BIWEEKLY") {
    data["biweekly"] =
      payload.value.biweekly &&
      typeof payload.value.biweekly != "undefined" &&
      payload.value.biweekly !== "null"
        ? payload.value.biweekly
        : null;
  } else {
    delete data["biweekly"];
  }
  try {
    queryLoading.value = true;
    await query({
      endpoint: "ViewPayrollForMonthV3",
      payload: {
        input: data,
      },
      service: "PAYROLL",
      storeKey: "payrollForMonth",
    });
    queryLoading.value = false;
  } catch (err) {
    queryLoading.value = false;
    console.log(err);
  }
};

const searchFN = async (suggested) => {
  // console.log(searchText.value);
  isSearch.value = true;
  payload.value.paging.search = searchText.value;
  await queryViewPayrollForMonthV3();
};

const filterFN = async ({ filter, active }) => {
  // console.log(filter, active);
  isSearch.value = active.length ? true : false;
  filterOptions.value = filter;
  payload.value.sort = filterOptions.value.sort;

  if (filterOptions.value.filter.length) {
    paymentStatusFilterOption.value = filterOptions.value.filter[0].value;
    // console.log("Filter By Payment Status", paymentStatusFilterOption.value);
  } else {
    paymentStatusFilterOption.value = "all";
  }

  // console.log(payload.value);
  await queryViewPayrollForMonthV3();

  activeFilters.value = active;
};

const removeFilter = (arg) => {
  // console.log(payrollHistoryDetailRef.value);
  if (
    payrollHistoryDetailRef.value &&
    payrollHistoryDetailRef.value.removeFilter
  ) {
    payrollHistoryDetailRef.value.removeFilter(arg);
  }
};

function updateSelectedEmployees(arg) {
  selectedEmployees.value = [];
  selectedEmployees.value = arg;
}

function toRunPayroll() {
  const payrollDate = route.params.date.split(",");
  const month = parseInt(payrollDate[0]);
  const year = parseInt(payrollDate[1]);
  const date = `${month}-${year}`;
  store.$patch({
    employeeArrayForPayroll: selectedEmployees.value,
  });
  router.push({ name: "PaySalary", params: { id: date } });
}

const handleShare = async () => {
  shareModal.value = true;
};

const handleDownload = async (print) => {
  const payrollDate = route.params.date.split(",");
  const month = parseInt(payrollDate[0]);
  const year = parseInt(payrollDate[1]);
  const timeline = payrollDate[2];
  const biweekly = payrollDate[3];

  try {
    downloadLoading.value = true;
    const res = await query({
      endpoint: "DownloadDetailedPayrollHistory",
      payload: {
        input: {
          month,
          year,
          timeline,
          biweekly,
        },
        options: {
          sendToCompanyAdmin: null,
          sendToAuditors: null,
        },
      },
      service: "PAYROLL",
    });
    downloadLoading.value = false;
    const base64 = res.data.base64;
    const fileName = res.data.fileName;

    downloadBase64(base64, fileName);
  } catch (error) {
    downloadLoading.value = false;
    console.log(error.message);
  }
};

const handlePrint = async (print) => {
  const payrollDate = route.params.date.split(",");
  const month = parseInt(payrollDate[0]);
  const year = parseInt(payrollDate[1]);
  const timeline = payrollDate[2];
  const biweekly = payrollDate[3];

  try {
    printLoading.value = true;
    const res = await query({
      endpoint: "DownloadDetailedPayrollHistory",
      payload: {
        input: {
          month,
          year,
          timeline,
          biweekly,
        },
        options: {
          sendToCompanyAdmin: null,
          sendToAuditors: null,
        },
      },
      service: "PAYROLL",
    });
    printLoading.value = false;
    const { base64 } = res.data;

    printPDF(base64);
  } catch (error) {
    printLoading.value = false;
    console.log(error.message);
  }
};

const printPage = async () => {
  onPrint.value = true;
  setTimeout(() => {
    window.print();
  }, 2000);
};

onafterprint = (event) => {
  onPrint.value = false;
};

const goBack = () => {
  // console.log(route.params);
  if (route.params) {
    router.replace({ params: null });
  }
  router.push({ name: "PayrollHistory" });
};

// function empty() {
//   getAllEmployees.value.data = [];
//   console.log(getAllEmployees.value.data);
// }

const display = (arg) => {
  return arg ? arg : "N/A";
};
</script>

<style></style>
