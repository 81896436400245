<template>
  <div>
    <!-- //////// Desktop View Start //////// -->
    <!-- /////////// Search Not found ////// -->

    <div
      v-if="isSearch && !visibleData.length"
      class="container mx-auto items-center justify-center w-full sm:w-11/12 md:w-10/12 my-6 grid grid-cols-2 gap-2 mt-12"
    >
      <div class="flex flex-col text-left col-span-2 md:col-span-1">
        <h1 class="text-secondary text-5xl md:text-7xl font-semibold mb-4">
          Oops!
        </h1>
        <div class="text-sm md:text-lg">
          Sorry we couldn’t find a result for your request.
        </div>
        <p class="text-xs md:text-sm mt-2">
          Please check your search and filter, then try again.
        </p>
      </div>
      <div class="col-span-2 md:col-span-1">
        <img src="@/assets/icons/search_null.svg" alt="" />
      </div>
    </div>

    <div v-else>
      <div class="hidden md:flex flex-col text-right pt-2">
        <div
          v-if="visibleData.length"
          class="overflow-x-auto rounded-t-3xl text-primary"
        >
          <table style="min-width: 900px" class="w-full relative">
            <thead class="">
              <tr class="text-header font-bold shadow-md bg-dark-100 relative">
                <th
                  class="bg-tableHead border border-outlineGray font-bold capitalize text-sm tracking-wide text-dark-800 logo text-center whitespace-nowrap p-0"
                >
                  <div class="border px-2 py-3">
                    <input
                      type="checkbox"
                      class=""
                      :checked="allChecked"
                      @change="toggleCheckAll()"
                    />
                  </div>
                </th>

                <th
                  v-for="(title, index) in headers"
                  :key="index"
                  class="bg-tableHead font-bold uppercase text-sm tracking-wide text-dark-800 logo text-center whitespace-nowrap p-0"
                >
                  <div class="px-2 py-3 border">
                    {{ title }}
                  </div>
                </th>
              </tr>
            </thead>
            <div class="p-2"></div>
            <tbody class="relative">
              <tr
                v-for="employee in visibleData"
                :key="employee"
                class="hover:bg-easigrey group bg-white text-tableBpdy cursor-pointer relative"
              >
                <td
                  class="border border-outlineGray text-left text-sm p-2 bg-white"
                >
                  <div class="text-center">
                    <input
                      :checked="
                        selectedEmployees.includes(
                          employee && employee.employeeId
                        )
                      "
                      @change="selectEmployee(employee && employee.employeeId)"
                      type="checkbox"
                      name=""
                      id=""
                    />
                  </div>
                </td>

                <td
                  @click="viewEmployee(employee.employeeId)"
                  class="border border-outlineGray text-left bg-background-200 text-sm p-2 group-hover:bg-primary-100 group-hover:border-primary-300"
                >
                  <div class="flex items-center gap-2 capitalize">
                    <div
                      v-if="
                        employee &&
                        employee.employee.profile &&
                        employee.employee.profile.pfp
                      "
                      class="border w-9 h-9 rounded-full overflow-hidden"
                    >
                      <img
                        class="w-full h-full object-cover"
                        :src="
                          employee &&
                          employee.employee.profile &&
                          employee.employee.profile.pfp
                        "
                        :alt="
                          employee &&
                          employee.employee.profile &&
                          employee.employee.profile.firstName +
                            ' ' +
                            employee &&
                          employee.employee.profile &&
                          employee.employee.profile.lastName
                        "
                      />
                    </div>
                    <div
                      v-else
                      class="bg-primary p-2 h-9 w-9 text-sm uppercase text-white font-medium rounded-full text-center"
                    >
                      {{ getAvatar(employee && employee.employee).name }}
                    </div>
                    {{
                      display(
                        employee &&
                          employee.employee.profile &&
                          employee.employee.profile.firstName
                      )
                    }}
                  </div>
                </td>
                <td
                  @click="viewEmployee(employee && employee.employeeId)"
                  class="border border-outlineGray text-left bg-background-200 text-sm p-2 capitalize"
                >
                  {{
                    display(
                      employee &&
                        employee.employee.profile &&
                        employee.employee.profile.lastName
                    )
                  }}
                </td>

                <td
                  @click="viewEmployee(employee && employee.employeeId)"
                  class="border border-outlineGray text-left text-sm p-2"
                >
                  {{ formatAmount(employee && employee.grossAmount) }}
                </td>
                <td
                  @click="viewEmployee(employee && employee.employeeId)"
                  class="border border-outlineGray text-left text-sm p-2"
                >
                  {{ formatAmount(employee && employee.netAmount) }}
                </td>
                <td
                  @click="viewEmployeeemployee && employee.employeeId"
                  class="border border-outlineGray text-left text-sm p-2"
                >
                  {{ formatDateString(employee && employee.createdAt) }}
                </td>
                <td
                  @click="viewEmployee(employee && employee.employeeId)"
                  class="border border-outlineGray text-left text-sm p-2 font-semibold capitalize"
                  :class="getStatus(employee && employee.status)"
                >
                  {{ employee && employee.status }}
                </td>
                <td
                  class="border border-outlineGray text-left text-xs p-2 font-medium"
                >
                  <div
                    class="static flex justify-between w-full"
                    ref="parent"
                    @scroll="checkDropdownPosition"
                  >
                    <div
                      @click="showAll(employee.employeeId)"
                      class="absolute cursor-pointer -mt-2 right-10 z-1 w-4"
                    >
                      <img src="@/assets/icons/more-icon.svg" alt="" />
                    </div>

                    <div
                      v-show="showOptions === employee.employeeId"
                      class="w-52 absolute group-hover:text-primary z-50 flex right-10 flex-col bg-white rounded-2xl shadow-lg"
                      :style="{ top: dropdownTop, bottom: dropdownBottom }"
                      style="z-index: 1"
                    >
                      <div
                        class="flex flex-col z-50 text-left items-start text-dark-800 text-sm cursor-pointer pt-2"
                      >
                        <span
                          @click="moreDetails = true"
                          class="hover:bg-newLimeGreen font-medium text-sm py-2 px-4 w-full"
                        >
                          View details
                        </span>

                        <span
                          @click="viewEmployee(employee && employee.employeeId)"
                          class="hover:bg-newLimeGreen font-medium text-sm py-2 px-4 w-full"
                          >View payslip</span
                        >
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- //////// Desktop View Ends ////////  -->

      <!-- //////// Mobile View Start //////// -->
      <div class="flex md:hidden w-full" v-if="visibleData.length">
        <div class="w-full">
          <div class="w-full flex items-center justify-between mb-2">
            <div class="flex items-center gap-2">
              <input
                type="checkbox"
                :checked="allChecked"
                class="h-4 w-4"
                @change="toggleCheckAll()"
              />

              <span class="text-dark-600">Select All</span>
            </div>

            <div>
              <span
                >{{
                  currentPage == 0 ? currentPage + 1 : pageSize + currentPage
                }}
                -
                {{
                  currentPage == 0
                    ? pageSize > allEmployees && allEmployees.length
                      ? allEmployees && allEmployees.length
                      : pageSize
                    : pageSize + visibleData.length
                }}
                of
                {{ allEmployees && allEmployees.length }}</span
              >
            </div>
          </div>

          <div
            v-for="employee in visibleData"
            :key="employee"
            class="w-full mb-8"
          >
            <table class="table-auto w-full relative overflow-y-auto">
              <tbody class="font-medium text-sm">
                <tr>
                  <td
                    class="w-4/12 border p-2 bg-newerDirtyBrown border-newLimeGreen"
                  >
                    <div class="flex items-center gap-2">
                      <input
                        :checked="
                          selectedEmployees.includes(
                            employee && employee.employeeId
                          )
                        "
                        @change="
                          selectEmployee(employee && employee.employeeId)
                        "
                        type="checkbox"
                        name=""
                        id=""
                      />
                      First Name
                    </div>
                  </td>
                  <td
                    class="w-8/12 border border-primary-200 text-left p-2 px-3 bg-newLimeGreen group-hover:bg-primary-100 group-hover:border-primary-300"
                  >
                    <div
                      @click="viewEmployee(employee && employee.employeeId)"
                      class="flex items-center gap-2"
                    >
                      <div
                        v-if="
                          employee &&
                          employee.employee.profile &&
                          employee.employee.profile.pfp
                        "
                        class="border w-8 h-8 rounded-full overflow-hidden"
                      >
                        <img
                          class="w-full h-full object-cover"
                          :src="
                            employee &&
                            employee.employee.profile &&
                            employee.employee.profile.pfp
                          "
                          :alt="
                            employee &&
                            employee.employee.profile &&
                            employee.employee.profile.firstName +
                              ' ' +
                              employee &&
                            employee.employee.profile &&
                            employee.employee.profile.lastName
                          "
                        />
                      </div>
                      <div
                        v-else
                        class="bg-primary uppercase p-2 h-8 w-8 text-xs text-white text-center font-medium rounded-full"
                      >
                        {{ getAvatar(employee && employee.employee).name }}
                      </div>
                      {{
                        display(
                          employee &&
                            employee.employee.profile &&
                            employee.employee.profile.firstName
                        )
                      }}
                    </div>

                    <div
                      class="static flex justify-between w-full"
                      ref="parent"
                      @scroll="checkDropdownPosition"
                    >
                      <div
                        @click="showAll(employee.employeeId)"
                        class="absolute cursor-pointer -mt-6 right-2 z-1 w-4"
                      >
                        <img src="@/assets/icons/more-icon.svg" alt="" />
                      </div>

                      <div
                        v-show="showOptions === employee.employeeId"
                        class="w-52 absolute group-hover:text-primary z-50 flex right-10 flex-col bg-white rounded-2xl shadow-lg"
                        :style="{ top: dropdownTop, bottom: dropdownBottom }"
                        style="z-index: 1"
                      >
                        <div
                          class="flex flex-col z-50 text-left items-start text-dark-800 text-sm cursor-pointer pt-2"
                        >
                          <span
                            @click="moreDetails = true"
                            class="hover:bg-newLimeGreen font-medium text-sm py-2 px-4 w-full"
                          >
                            View details
                          </span>

                          <span
                            @click="
                              viewEmployee(employee && employee.employeeId)
                            "
                            class="hover:bg-newLimeGreen font-medium text-sm py-2 px-4 w-full"
                            >View payslip</span
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr @click="viewEmployee(employee && employee.employeeId)">
                  <td
                    class="w-4/12 border p-2 bg-newerDirtyBrown border-outlineGray"
                  >
                    <div class="flex items-center gap-2">Last Name</div>
                  </td>
                  <td
                    class="w-8/12 border border-outlineGray text-left p-2 bg-newLimeGreen px-3"
                  >
                    {{
                      display(
                        employee &&
                          employee.employee.profile &&
                          employee.employee.profile.lastName
                      )
                    }}
                  </td>
                </tr>
                <tr @click="viewEmployee(employee && employee.employeeId)">
                  <td
                    class="w-4/12 border p-2 bg-newerDirtyBrown border-outlineGray"
                  >
                    <div class="flex items-center gap-2">Gross Pay</div>
                  </td>
                  <td
                    class="w-8/12 border bg-white border-outlineGray text-left p-2 px-3"
                  >
                    {{ formatAmount(employee && employee.grossAmount) }}
                  </td>
                </tr>

                <tr @click="viewEmployee(employee && employee.employeeId)">
                  <td
                    class="w-4/12 border p-2 bg-newerDirtyBrown border-outlineGray"
                  >
                    <div class="flex items-center gap-2">Net Pay</div>
                  </td>
                  <td
                    class="w-8/12 border border-outlineGray bg-white text-left p-2 px-3"
                  >
                    {{ formatAmount(employee && employee.netAmount) }}
                  </td>
                </tr>

                <tr @click="viewEmployee(employee && employee.employeeId)">
                  <td
                    class="w-4/12 border p-2 bg-newerDirtyBrown border-outlineGray"
                  >
                    <div class="flex items-center gap-2">Status</div>
                  </td>
                  <td
                    class="w-8/12 border capitalize border-outlineGray text-left p-2 px-3 bg-white"
                    :class="getStatus(employee && employee.status)"
                  >
                    {{ employee && employee.status }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- //////// Mobile View End //////// -->

    <div
      @click="showOptions = ''"
      :class="!showOptions.length ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>

    <easiModal
      v-if="moreDetails"
      size="2xl"
      pixelRounded="40px"
      :isBorder="false"
      @close="moreDetails = false"
    >
      <div class="flex flex-col gap-3 px-4">
        <h1 class="text-primary-deep text-lg font-medium">More details</h1>

        <div class="grid grid-cols-2 gap-x-3 gap-y-4 w-full">
          <div
            style="background-color: #f8f8f8; border-radius: 16px"
            class="px-3 py-3 flex flex-col w-full gap-1 border border-newAsh"
          >
            <h3 class="text-xs text-newGray">Bank name</h3>
            <p class="text-dark-800 font-medium text-sm">Access Bank</p>
          </div>

          <div
            style="background-color: #f8f8f8; border-radius: 16px"
            class="px-3 py-3 flex flex-col w-full gap-1 border border-newAsh"
          >
            <h3 class="text-xs text-newGray">Bank name</h3>
            <p class="text-dark-800 font-medium text-sm">Access Bank</p>
          </div>

          <div
            style="background-color: #f8f8f8; border-radius: 16px"
            class="px-3 py-3 flex flex-col w-full gap-1 border border-newAsh"
          >
            <h3 class="text-xs text-newGray">Payment reference</h3>
            <div
              class="flex items-center md:flex-nowrap justify-between flex-wrap"
            >
              <p class="text-dark-800 font-medium text-sm">
                hladufhkhxchl khcahbdck
              </p>

              <div
                style="border-radius: 8px"
                @click="copyText('hladu')"
                class="cursor-pointer bg-newAsh p-2"
              >
                <img class="w-5" src="@/assets/icons/clipboard.svg" alt="" />
              </div>
            </div>
          </div>

          <div
            style="background-color: #f8f8f8; border-radius: 16px"
            class="px-3 py-3 flex flex-col w-full gap-1 border border-newAsh"
          >
            <h3 class="text-xs text-newGray">Session ID</h3>
            <div
              class="flex items-center md:flex-nowrap justify-between flex-wrap"
            >
              <p class="text-dark-800 font-medium text-sm">
                hladufhkhxchl khcahbdck
              </p>

              <div
                style="border-radius: 8px"
                @click="copyText('hladu')"
                class="cursor-pointer bg-newAsh p-2"
              >
                <img class="w-5" src="@/assets/icons/clipboard.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </easiModal>
  </div>

  <!-- //////// Pagination //////// -->
  <easiPagin
    v-if="allEmployees && allEmployees.length"
    :data="allEmployees"
    @page:update="updatePage"
    :currentPage="currentPage"
    :pageSize="pageSize"
    class="my-4"
  />
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";
import EmptyTable from "@/components/global/emptyTable.vue";

const { formatAmount, formatDateString, getAvatar } = helperFunctions;
const route = useRoute();
const router = useRouter();
const store = useDataStore();
const toast = useToast();

const props = defineProps({
  isSearch: {
    type: Boolean,
    default: false,
  },
  searchText: {
    type: String,
    default: "",
  },
  employees: {
    type: Array,
    default: [],
  },
  update: {
    type: Function,
  },
  paymentStatus: {
    type: String,
  },
});
const dropdownTop = ref("0%");
const dropdownBottom = ref("auto");
const showOptions = ref(false);
const moreDetails = ref(false);
const emit = defineEmits(["selected"]);
const watchPaymentStatus = computed(() => props.paymentStatus);
const watchSearch = computed(() => props.isSearch);

const currentPage = ref(0);
const pageSize = ref(15);
const visibleData = ref([]);
const allChecked = ref(false);
const selectedEmployees = ref([]);
const parent = ref(null);

function copyText(val) {
  var text = val;
  navigator.clipboard.writeText(text);
  toast.info("Copied!");
}
function showAll(arg) {
  if (showOptions.value === arg) {
    showOptions.value = "";
  } else {
    showOptions.value = arg;
  }
}
function checkDropdownPosition() {
  const parentElement = parent.value;
  const parentHeight = parentElement.clientHeight;
  const parentScrollTop = parentElement.scrollTop;

  const dropdownElement = this.$el;
  const dropdownHeight = dropdownElement.clientHeight;

  const dropdownTopPercentage = (parentScrollTop / parentHeight) * 100;
  const dropdownBottomPercentage =
    ((parentHeight - (parentScrollTop + dropdownHeight)) / parentHeight) * 100;

  dropdownTop.value = dropdownTopPercentage + "%";
  dropdownBottom.value = dropdownBottomPercentage + "%";
}

const headers = reactive([
  "First Name",
  "Last Name",
  "Gross Pay",
  "Net Pay",
  "Date Paid",
  "Status",
  "Action",
]);

const payrollForMonth = computed(() => store.getPayrollHistoryForMonth);

console.log("PAYROLL", payrollForMonth.value);

const allEmployees = computed(() =>
  payrollForMonth.value ? payrollForMonth.value.salaryRecords : []
);

const getStatus = (arg) => {
  const status = {
    paid: "text-success",
    failed: "text-error",
    processing: "text-pendingStatus",
  };

  return arg ? status[arg] : "";
};

onMounted(async () => {
  await updateVisibleData();
});

watch(payrollForMonth, () => {
  updateVisibleData();
});

watch(watchPaymentStatus, () => {
  updateVisibleData();
});
watch(watchSearch, () => {
  updateVisibleData();
});

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};

const updateVisibleData = async () => {
  if (payrollForMonth.value) {
    let datas = payrollForMonth.value.salaryRecords;

    if (props.paymentStatus !== "all") {
      datas = datas.filter((emp) => {
        if (emp.status == props.paymentStatus.toLowerCase()) {
          return emp;
        }
      });
    }

    if (props.isSearch) {
      datas = datas.filter(
        (emp) =>
          emp.employee.profile.firstName
            .toLowerCase()
            .includes(props.searchText.toLowerCase()) ||
          emp.employee.profile.lastName
            .toLowerCase()
            .includes(props.searchText.toLowerCase())
      );
    }

    console.log("DATAS", datas);

    visibleData.value = datas.slice(
      currentPage.value * pageSize.value,
      currentPage.value * pageSize.value + pageSize.value
    );

    if (visibleData.value.length == 0 && currentPage.value > 0) {
      updatePage(currentPage.value - 1);
    }
  } else {
    visibleData.value = [];
  }
};

const toggleCheckAll = () => {
  allChecked.value = !allChecked.value;

  if (allChecked.value) {
    allEmployees.value.forEach((emp) => {
      if (!selectedEmployees.value.includes(emp.employeeId)) {
        ////// Toggle only employee that matches the payment status filter if filter is on ///
        if (props.paymentStatus !== "all") {
          if (emp.status == props.paymentStatus.toLowerCase()) {
            selectedEmployees.value.push(emp.employeeId);
          }
        } else {
          selectedEmployees.value.push(emp.employeeId);
        }
      }
    });
  } else {
    selectedEmployees.value = [];
  }

  // console.log("selected", selectedEmployees.value);

  emit("selected", selectedEmployees.value);
};

const selectEmployee = async (arg) => {
  allChecked.value = false;

  if (selectedEmployees.value.includes(arg)) {
    selectedEmployees.value = [
      ...selectedEmployees.value.filter((id) => arg !== id),
    ];
  } else {
    selectedEmployees.value.push(arg);
  }

  // console.log("SELECTED", selectedEmployees.value);

  emit("selected", selectedEmployees.value);
};

const viewEmployee = (id) => {
  if (typeof id == "string") {
    router.push({
      name: "PayrollHistoryDetails",
      query: { id: id },
    });
  }
};

function display(arg) {
  return arg ? arg : "N/A";
}
</script>

<style></style>
